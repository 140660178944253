<template>
  <a class="button-twitter-follow" :href="twitterUrl" target="_blank">
    <IconX />
    <span class="button-twitter-follow__text"> auf twitter folgen </span>
  </a>
</template>

<script>
import IconX from '@/components/ui/icons/icon-x.svg'

export default {
  components: {
    IconX,
  },
  props: {
    twitterUsername: {
      type: String,
      required: true,
    },
  },
  computed: {
    twitterUrl() {
      return `http://www.twitter.com/${this.twitterUsername}`
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.button-twitter-follow {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 2px;
  background-color: unset;
  border: vars.$color-light-grey solid 1px;
  color: vars.$color-line-gray;
  white-space: nowrap;

  @media (min-width: vars.$screen-width-767) {
    padding: 0 10px;
  }

  svg {
    @media (min-width: vars.$screen-width-767) {
      margin-right: 10px;
    }
  }

  &__text {
    display: none;

    @media (min-width: vars.$screen-width-767) {
      display: block;
    }
  }
}
</style>

<template>
  <GridRow>
    <div class="five-teaser-row-with-ads">
      <div class="five-teaser-row-with-ads__default">
        <div
          v-for="(article, index) in smallTeasers"
          :key="article.id"
          class="five-teaser-row-with-ads__smallteaser"
          :class="{ 'five-teaser-row-with-ads__smallteaser--medium-r': index === 1 && adsEnabled && showAdPosition }"
        >
          <template v-if="index === 1 && adsEnabled && showAdPosition">
            <MediumRectangle desktop-id="sas_22373" mobile-id="sas_23362" />
          </template>
          <template v-else>
            <OpinionTeaser v-if="article.attributes.textType === 'Meinung'" :article="article" :medium-teaser="true" />
            <FloatingImageTeaser v-else :article="article" :lazy="lazy" />
          </template>
        </div>
      </div>
      <div class="five-teaser-row-with-ads__bgteaser">
        <BackgroundTeaser :article="bigTeaser" class="five-teaser-row-with-ads__bigteaser" />
      </div>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import FloatingImageTeaser from '@/components/ui/teaser/floating-image-teaser'
import OpinionTeaser from '@/components/ui/teaser/opinion-teaser'
import BackgroundTeaser from '@/components/ui/teaser/background-teaser'
import MediumRectangle from '@/components/ads/medium-rectangle'

export default {
  components: {
    GridRow,
    FloatingImageTeaser,
    OpinionTeaser,
    BackgroundTeaser,
    MediumRectangle,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    showAds: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    smallTeasers() {
      const articles = [...this.box.relationships.articles]

      if (this.adsEnabled) {
        if (articles.length > 1) {
          const lastArticle = articles.pop()
          articles.splice(1, 0, lastArticle)
        }
      }

      return articles.slice(0, 4)
    },
    bigTeaser() {
      if (this.adsEnabled) {
        return this.box.relationships.articles.slice(-2)[0]
      }
      return this.box.relationships.articles.slice(-1)[0]
    },
    adsEnabled() {
      return this.$config.adsEnabled
    },
    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },
    showAdPosition() {
      return !(
        this.noAdPositions.map((element) => element.formatId).includes('22373') ||
        this.noAdPositions.map((element) => element.formatId).includes('23362')
      )
    },
  },
}
</script>

<style lang="scss">
@use '../../../../css/import/vars';

.five-teaser-row-with-ads {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media all and (min-width: vars.$screen-width-767) {
    padding: 0 0 0 14px;
  }

  @media all and (min-width: vars.$screen-width-1023) {
    flex-direction: row;
  }

  @media all and (min-width: 1200px) {
    padding: 0;
  }

  &__default {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media all and (min-width: vars.$screen-width-767) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media all and (min-width: vars.$screen-width-1023) {
      flex-basis: 585px;
      flex-shrink: 0;
      margin: 0;
    }
  }

  &__smallteaser {
    position: relative;
    padding: 20px 0;

    &:nth-child(1) {
      padding-top: 0;
    }

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 1px;
      width: calc(100%);
      background-color: vars.$color-light-grey;
    }

    @media all and (min-width: vars.$screen-width-767) {
      padding: 20px 14px;

      &::after {
        width: calc(100% - 14px);
      }
    }

    &:nth-child(odd) {
      flex-basis: 50%;

      @media all and (min-width: vars.$screen-width-767) {
        padding-left: 0;
        border-right: 1px solid vars.$color-light-grey;

        &::after {
          left: 0;
        }
      }

      @media all and (min-width: vars.$screen-width-1023) {
        flex-basis: calc(50% - 10px);
      }
    }

    &:nth-child(even) {
      flex-basis: 50%;

      @media all and (min-width: vars.$screen-width-767) {
        padding-right: 0;

        &::after {
          right: 0;
        }
      }

      @media all and (min-width: vars.$screen-width-1023) {
        padding-right: 14px;
        flex-basis: calc(50% + 10px);
        border-right: 1px solid vars.$color-light-grey;

        &::after {
          right: 14px;
          width: calc(100% - 28px);
        }
      }
    }

    // first two teasers
    &:nth-child(-n + 2) {
      @media all and (min-width: vars.$screen-width-767) {
        padding-top: 0;
        padding-bottom: 18px;
      }
    }

    // last two teasers
    &:nth-child(n + 3) {
      @media all and (min-width: vars.$screen-width-767) {
        padding-top: 10px;
      }

      @media all and (min-width: vars.$screen-width-1023) {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }

    .floating-image-teaser,
    .opinion-teaser {
      height: 100%;
    }

    &--medium-r {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0 20px 0 !important;

      @media all and (min-width: vars.$screen-width-767) {
        padding: 0 !important;
      }
    }
  }

  &__bgteaser {
    max-width: 570px;
    margin: 0 auto;
    display: flex;

    @media all and (min-width: vars.$screen-width-1023) {
      margin: 0;
      max-width: 100%;
      flex: 1 1 50%;
    }
  }

  &__bigteaser {
    @media all and (min-width: vars.$screen-width-767) {
      padding-left: 14px;
      padding-bottom: 0;
    }

    .responsive-image {
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>

<template>
  <article class="hint-teaser">
    <div class="hint-teaser__header">
      <div class="hint-teaser__badges">
        <ContentTypeBadge :f-plus="article.attributes.payWall" :online-first="article.attributes.onlineFirst" />
        <div v-if="article.attributes.textType === 'Tipp'" class="hint-teaser__top">
          <HintTag> Veranstaltungstipp </HintTag>
          <p v-if="article.attributes.tipDate" class="hint-teaser__top-infodate">
            {{ article.attributes.tipDate }}
          </p>
        </div>
      </div>
      <div class="hint-teaser__content">
        <SubHeadline class="hint-teaser__headline">
          <ArticleLink v-snip="2" :article="article" :title="article.attributes.teaser.title" class="hint-teaser__link">
            {{ article.attributes.teaser.title }}
          </ArticleLink>
        </SubHeadline>
        <SpunqImage
          v-if="article.attributes.teaser.image"
          class="hint-teaser__image"
          :image="article.attributes.teaser.image"
          :width="600"
          :height="400"
          :mobile-width="300"
          :mobile-height="200"
          mode="crop"
          :lazy="lazy"
        />
        <LeadText v-if="article.attributes.teaser.leadText" class="hint-teaser__text">
          {{ article.attributes.teaser.leadText }}
        </LeadText>
      </div>
    </div>
    <div v-if="article.attributes.textType === 'Tipp'" class="hint-teaser-footer">
      <p v-if="article.attributes.tipLocation" class="hint-teaser-footer__infotext">
        {{ article.attributes.tipLocation }}
      </p>
      <p v-if="article.attributes.tipDate" class="hint-teaser-footer__infodate">
        {{ article.attributes.tipDate }}
      </p>
    </div>
  </article>
</template>

<script>
import HintTag from '@/components/ui/tag/hint'
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import SpunqImage from '@/components/ui/image/spunq-image'
import ArticleLink from '@/components/article/link'
import ContentTypeBadge from '@/components/ui/badge/content-type'

export default {
  components: {
    HintTag,
    SubHeadline,
    ArticleLink,
    SpunqImage,
    LeadText,
    ContentTypeBadge,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.hint-teaser {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media all and (min-width: vars.$screen-width-767) {
    padding: 0 13px;
  }

  &:hover {
    .default-teaser__picture {
      img {
        transform: scale(1.01);
      }
    }
  }

  &__badges {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;

    @media all and (min-width: 375px) {
      flex-wrap: nowrap;
    }
  }

  &__headline {
    margin: 0;
    font-weight: 800;
    font-variation-settings: 'wght' 800;
    font-size: 24px;
    line-height: 35px;
    order: 2;
    @media all and (min-width: vars.$screen-width-767) {
      font-size: 32px;
    }
  }

  &__link {
    color: vars.$color-text-dark-gray;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    order: 3;
  }

  &__image {
    order: 1;

    @media all and (min-width: vars.$screen-width-767) {
      margin-left: 30px;
      margin-bottom: 10px;
      max-width: 200px;
      float: right;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media all and (min-width: vars.$screen-width-767) {
      display: inline-block;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20px;

    &-infodate {
      font-size: 14px;
      line-height: 18px;
      color: vars.$color-text;
      font-weight: 600;
      font-variation-settings: 'wght' 600;
      display: block;
      margin-left: 20px;

      @media all and (min-width: vars.$screen-width-767) {
        display: none;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    &__infotext {
      font-size: 13px;
      line-height: 17px;
      color: vars.$color-text;
      font-weight: 600;
      font-variation-settings: 'wght' 600;
    }

    &__infodate {
      display: none;

      @media all and (min-width: vars.$screen-width-767) {
        display: block;
        flex: 0 0 200px;
        text-align: right;
        font-size: 14px;
        line-height: 18px;
        color: vars.$color-text;
        font-weight: 600;
        font-variation-settings: 'wght' 600;
        margin-left: 20px;
      }
    }
  }
}
</style>

<template>
  <div>
    <SuperBanner desktop-id="sas_22374" />
    <DefaultLayout>
      <PageHeader :darkmode="darkmode" />
      <GridHolder v-if="channels" :class="{ 'without-hero': withoutHeroBox }">
        <template v-for="(box, index) in channels[0].relationships.boxes">
          <div
            :is="sanitizeBoxTemplate(box.attributes.templateAlias)"
            v-if="sanitizeBoxTemplate(box.attributes.templateAlias) === 'authors-box'"
            :key="parseInt(box.id)"
            :box="box"
            :star-authors="starAuthors"
          />
          <div
            v-else
            :is="sanitizeBoxTemplate(box.attributes.templateAlias)"
            :key="parseInt(box.id)"
            :lazy="index > 1 ? true : false"
            :box="box"
          />
        </template>
      </GridHolder>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from '@/components/ui/layout/default'
import PageHeader from '@/components/header/default'

import GridHolder from '@/components/ui/grid/grid'
import Hero from '@/components/channel/boxes/content-box/hero'
import HeroWithAd from '@/components/channel/boxes/content-box/hero-with-ad'
import FourTeaserRow from '@/components/channel/boxes/content-box/four-teaser-row'
import FourTeaserRowWithAd from '@/components/channel/boxes/content-box/four-teaser-row-with-ad'
import FiveTeaserRow from '@/components/channel/boxes/content-box/five-teaser-row'
import FiveTeaserRowWithAd from '@/components/channel/boxes/content-box/five-teaser-row-with-ad'
import DossierBox from '@/components/channel/boxes/content-box/dossier-box'
import DossierTeaser from '@/components/channel/boxes/content-box/dossier-teaser'
import booksandfilm from '@/components/channel/boxes/box/books-and-film'
import TwoTeaserRow from '@/components/channel/boxes/content-box/two-teaser-row'
import AuthorsBox from '@/components/channel/boxes/box/authors'
import NewsletterTeaser from '@/components/channel/boxes/content-box/newsletter-teaser'
import SuperBanner from '@/components/ads/superbanner'
import Promotion from '@/components/channel/boxes/box/promotion'
import EightTeaserRowWithAd from './boxes/content-box/eight-teaser-row-with-ad.vue'

export default {
  components: {
    PageHeader,
    DefaultLayout,
    GridHolder,
    Hero,
    HeroWithAd,
    FourTeaserRow,
    FourTeaserRowWithAd,
    FiveTeaserRow,
    FiveTeaserRowWithAd,
    EightTeaserRowWithAd,
    DossierBox,
    DossierTeaser,
    booksandfilm,
    TwoTeaserRow,
    AuthorsBox,
    NewsletterTeaser,
    SuperBanner,
    Promotion,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    starAuthors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    darkmode() {
      if (!this.channels[0].relationships.boxes.length) {
        return false
      }
      if (!this.channels[0].relationships.boxes[0].relationships.articles.length) {
        return false
      }
      if (
        this.channels[0].relationships.boxes[0].relationships.articles[0].attributes.background === 1 &&
        (this.channels[0].relationships.boxes[0].attributes.templateAlias === 'cms.furche._boxes.ContentBox.hero' ||
          this.channels[0].relationships.boxes[0].attributes.templateAlias ===
            'cms.furche._boxes.ContentBox.dossier-box')
      ) {
        return true
      }
      return false
    },
    withoutHeroBox() {
      const firstChannelSlug = [].concat(this.$route.params.channelSlugs).shift() // cast to array as channelslugs can be string
      if (firstChannelSlug === 'dossier') {
        return false
      }
      if (this.sanitizeBoxTemplate(this.channels[0].relationships.boxes[0].attributes.templateAlias) === 'hero') {
        return false
      }
      if (
        this.sanitizeBoxTemplate(this.channels[0].relationships.boxes[0].attributes.templateAlias) === 'hero-with-ad'
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    sanitizeBoxTemplate(templateAlias) {
      let name = ''
      if (templateAlias.includes('ContentBox')) {
        name = templateAlias.replace(/cms\.furche\._boxes\.ContentBox\./, '')
      } else if (templateAlias.includes('Box')) {
        name = templateAlias.replace(/cms\.furche\._boxes\.Box\./, '')
      }
      if (name === 'authors') {
        return name + '-box'
      } else {
        return name
      }
    },
  },
}
</script>

<style lang="scss">
@use '../../css/import/vars';

.without-hero {
  margin-top: 80px;

  @media all and (min-width: vars.$screen-width-1023) {
    margin-top: 140px;
  }
}
</style>

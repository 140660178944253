<template>
  <GridRow class="newsletter-teaser">
    <div v-if="teaser.image" class="_image">
      <SpunqImage
        :image="teaser.image"
        :width="400"
        :height="400"
        :mobile-width="300"
        :mobile-height="300"
        :lazy="lazy"
      />
    </div>
    <div class="_content">
      <h2>{{ teaser.title }}</h2>
      <p>{{ teaser.leadText }}</p>
      <NuxtLink class="link-button" to="/newsletter">
        {{ teaser.buttonLabel }}
      </NuxtLink>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import SpunqImage from '@/components/ui/image/spunq-image'

export default {
  components: {
    SpunqImage,
    GridRow,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    teaser() {
      try {
        return {
          title: this.box.relationships.articles[0].attributes.teaser.title,
          leadText: this.box.relationships.articles[0].attributes.teaser.leadText,
          image: this.box.relationships.articles[0].attributes.teaser.image,
          buttonLabel: 'Zur Newsletter Anmeldung',
        }
      } catch (e) {
        return {
          title: 'Furche-Newsletter',
          leadText: '',
          buttonLabel: 'Zur Newsletter Anmeldung',
        }
      }
    },
  },
}
</script>

<style lang="scss">
@use '../../../../css/import/vars';

.newsletter-teaser {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 28px 20px 24px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f8f4ee;
  background-clip: padding-box;

  ._image {
    flex-basis: 150px;
    flex-grow: 0;
    margin-bottom: 16px;

    > .spunq-image {
      display: block;
      width: 100%;
    }

    img {
      box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.3);
    }
  }

  ._content {
    flex-basis: 300px;
    flex-grow: 1;
    text-align: center;
  }

  h2,
  p {
    font-family: vars.$source-sans-pro-variable;
    font-weight: 400;
    font-size: 16px;
    color: vars.$color-text;
    text-align: center;
  }

  h2 {
    margin-bottom: 12px;
    color: vars.$color-text-dark-gray;
    font-size: 32px;
    line-height: 1.06;
    font-weight: 700;
  }

  p {
    margin-bottom: 24px;
  }

  .link-button {
    display: inline-block;
    padding: 8px 14px 10px;
    line-height: 1;
  }

  @media screen and (min-width: vars.$screen-width-767) {
    padding-top: 42px;
    padding-bottom: 48px;

    ._image {
      flex-basis: 150px;
      flex-grow: 1;
      margin-bottom: 0;

      img {
        max-width: 150px;
        margin-left: auto;
      }
    }

    ._content {
      flex-grow: 1.5;
      padding-top: 10px;
      padding-left: 20px;
      text-align: left;
    }

    h2,
    p {
      text-align: left;
      max-width: 400px;
    }
  }
}
</style>

<template>
  <div class="default-slider default-slider--hero">
    <SliderButton :id="buttonPrevId" class="slider-button-prev slider-button__previous">
      <IconArrow fill="#000" />
    </SliderButton>
    <SliderButton :id="buttonNextId" class="slider-button-next slider-button__next">
      <IconArrow fill="#000" />
    </SliderButton>
    <div v-swiper:heroSwiper="swiperOptions">
      <div class="swiper-wrapper">
        <div v-for="relatedArticle in article.relationships.articles" :key="relatedArticle.id" class="swiper-slide">
          <SliderOpinionTeaser
            v-if="relatedArticle.attributes.textType === 'Meinung'"
            :article="relatedArticle"
            :miniteaser="true"
            class="one-fourth-teaser"
          />
          <SliderTeaser v-else :article="relatedArticle" :miniteaser="true" class="one-fourth-teaser" :lazy="lazy" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderButton from '@/components/ui/button/slide'
import IconArrow from '@/components/ui/icons/icon-arrow.svg'
import SliderTeaser from '@/components/ui/teaser/slider-teaser'
import SliderOpinionTeaser from '@/components/ui/teaser/slider-opinion-teaser'

export default {
  components: {
    SliderButton,
    IconArrow,
    SliderTeaser,
    SliderOpinionTeaser,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonPrevId() {
      return `hero-slider-button-prev-${this.article.id}`
    },
    buttonNextId() {
      return `hero-slider-button-next-${this.article.id}`
    },
    swiperOptions() {
      return {
        navigation: {
          prevEl: `#${this.buttonPrevId}`,
          nextEl: `#${this.buttonNextId}`,
        },
        slidesPerView: 2.5,
        spaceBetween: 20,
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: true,
        loop: false,
        breakpoints: {
          766: {
            slidesPerView: 1.5,
          },
          1023: {
            slidesPerView: 2.5,
          },
          1300: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
        },
      }
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.default-slider {
  &#{&}--hero {
    .slider-button-prev {
      left: 15px;
      position: absolute;
    }
    .slider-button-next {
      right: 30px;
      position: absolute;
    }
    .swiper-button-disabled {
      display: none;
    }

    @media all and (min-width: vars.$screen-width-767) {
      width: 100%;
      max-width: 880px;
      margin-left: 0px;
      flex-shrink: 1;
      flex-grow: 0;
      margin-left: 60px;
      overflow: hidden;
      position: relative;
    }

    .slider-teaser {
      display: flex;
      flex-direction: column;
      position: relative;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;

      @media all and (min-width: vars.$screen-width-767) {
        width: 100%;
        max-width: none;
        max-height: none;
      }

      &__title {
        order: 3;
        overflow: hidden;
        font-size: 15px;
        line-height: 130%;
        margin-bottom: 0;
        @media all and (min-width: vars.$screen-width-767) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      &__picture {
        order: 1;
        max-width: none;
        float: none;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
      }

      &__date {
        font-weight: 600;
        font-variation-settings: 'wght' 600;
        font-size: 12px;
        opacity: 0.6;
      }
    }

    .slider-teaser-opinion {
      display: flex;
      flex-direction: column;
      position: relative;
      border: none;

      @media all and (min-width: vars.$screen-width-767) {
        width: 100%;
        max-width: none;
        max-height: none;
      }

      &__title {
        overflow: hidden;
        font-size: 18px;
        margin-bottom: 0;

        @media all and (min-width: vars.$screen-width-767) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }

      &__footer {
        padding-top: 0;
      }

      &__date {
        font-weight: 600;
        font-variation-settings: 'wght' 600;
        font-size: 12px;
        opacity: 0.6;
      }
    }

    .one-fourth-teaser {
      border-bottom: none;
    }
    .slider-button__next {
      right: 30px;
    }
    .swiper-button-disabled {
      opacity: 0.2;
      display: none;
    }
  }

  .swiper-slide {
    height: auto;
  }
}

.darkmode {
  .default-teaser__date {
    color: vars.$color-white;
  }
}
</style>

<template>
  <div
    :class="['promotion-box', { 'promotion-box--with-button': computedButtonName }]"
    :style="{ backgroundColor: computedBackgroundColor }"
    target="_blank"
    @click="handleSectionClick"
  >
    <div class="promotion-box__content" :style="{ color: computedTextColor }">
      <h2 class="promotion-box__title">{{ title }}</h2>
      <p class="promotion-box__lead-text">{{ description }}</p>
      <a
        v-if="computedButtonName"
        :href="computedButtonLink"
        class="promotion-box__button"
        :style="{
          backgroundColor: computedBackgroundColor === '#FAFAFA' ? '#2C2C2D' : '#fff',
          color: computedBackgroundColor,
        }"
        >{{ computedButtonName }}</a
      >
    </div>
    <div v-if="image" class="promotion-box__image-container">
      <SpunqImage
        v-if="image"
        class="promotion-box__image"
        :image="image"
        :width="502"
        :height="400"
        :mobile-width="670"
        :mobile-height="442"
      />
    </div>
  </div>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
export default {
  components: {
    SpunqImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
    buttonLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    computedBackgroundColor() {
      return this.backgroundColor || '#FAFAFA'
    },
    computedButtonName() {
      return this.buttonName || ''
    },
    computedButtonLink() {
      return this.buttonLink || ''
    },
    computedTextColor() {
      const colorMap = {
        '#FAFAFA': '#2C2C2D', // Grey
        '#119AAA': '#ffffff', // Turquoise
        '#281E5A': '#ffffff', // Purple
      }
      return colorMap[this.computedBackgroundColor.toUpperCase()] || '#2C2C2D' // Default color
    },
  },
  methods: {
    handleSectionClick() {
      if (!this.computedButtonName) {
        window.location.href = this.computedButtonLink
      }
    },
  },
}
</script>

<style lang="scss">
@use '../../../../css/import/vars';
.promotion-box {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;
  &--with-button {
    cursor: unset;
  }
  &__image-container {
    height: 200px;
    display: flex;
    flex: 1;
    figure {
      flex: 1;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    padding: 15px;
    flex: 1;
  }

  &__title {
    font-size: 2rem;
    line-height: 1.1;
    margin-bottom: 0.5rem;
    word-wrap: break-word;
  }

  &__lead-text {
    font-size: 1.0625rem;
    font-family: vars.$georgia;
    line-height: 110%;
  }

  &__button {
    max-width: 184px;
    max-height: 50px;
    background-color: #ffffff;
    color: inherit;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 20px;
    text-decoration: none;
    border-radius: 6px;
    align-self: flex-end;
    padding: 0 10px;
    line-height: 50px;
    overflow: hidden;
  }

  @media screen and (min-width: vars.$screen-width-767) {
    flex-direction: row-reverse;
    &__title {
      font-size: 36px;
    }
    &__lead-text {
      font-size: 18px;
    }
    &__image-container {
      flex: 0 0 251px;
      height: auto;
      align-items: center;
      justify-content: center;
    }
    &__content {
      position: relative;
    }
    &__button {
      max-width: 156px;
      font-size: 20px;
    }
  }
}
</style>

<template>
  <div v-if="adsEnabled && showAdPosition" class="superbanner" :id="desktopId"></div>
</template>

<script>
export default {
  props: {
    desktopId: {
      type: String,
      required: true,
    },
  },
  computed: {
    adsEnabled() {
      return this.$config.adsEnabled
    },
    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },
    showAdPosition() {
      return !this.noAdPositions.map((element) => element.formatId).includes(this.desktopId.replace('sas_', ''))
    },
  },
}
</script>

<style lang="scss">
@use '../../css/import/vars';
.superbanner {
  display: none;

  @media all and (min-width: 768px) {
    width: 100%;
    max-width: 728px;
    margin: 20px auto;
    display: block;
  }
}
</style>

<template>
  <article class="floating-image-teaser">
    <div class="floating-image-teaser__content">
      <div class="floating-image-teaser__badges">
        <ContentTypeBadge :f-plus="article.attributes.payWall" :online-first="article.attributes.onlineFirst" />
        <p v-if="article.attributes.teaser.preTitle" class="floating-image-teaser__category">
          {{ article.attributes.teaser.preTitle }}
        </p>
        <p v-else-if="article.relationships.channels" class="floating-image-teaser__category">
          {{ article.relationships.channels | getChannelName($config) }}
        </p>
      </div>
      <SubHeadline v-if="article.attributes.teaser.title" tag="h2" class="floating-image-teaser__headline">
        <ArticleLink
          v-snip="3"
          :article="article"
          :title="article.attributes.teaser.title"
          class="floating-image-teaser__link"
        >
          {{ article.attributes.teaser.title }}
        </ArticleLink>
      </SubHeadline>
      <SpunqImage
        v-if="article.attributes.teaser.image"
        class="floating-image-teaser__image"
        :image="article.attributes.teaser.image"
        :width="600"
        :height="400"
        :mobile-width="300"
        :mobile-height="200"
        mode="crop"
        :lazy="lazy"
      />
      <LeadTextTeaser
        class="floating-image-teaser__leadtext"
        v-if="article.attributes.teaser.leadText"
        v-snip:js="screenType === 'xs' ? 8 : 6"
      >
        {{ article.attributes.teaser.leadText }}
      </LeadTextTeaser>
    </div>
    <footer class="floating-image-teaser__footer">
      <p v-if="article.relationships.authors.length > 0" class="floating-image-teaser__author">
        {{ article.relationships.authors[0].attributes.firstName }}
        {{ article.relationships.authors[0].attributes.lastName }}
      </p>
      <span class="floating-image-teaser__date">
        {{ article.attributes.frontendDate | formatDate($config) }}
      </span>
    </footer>
  </article>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import SpunqImage from '@/components/ui/image/spunq-image'
import ArticleLink from '@/components/article/link'
import LeadTextTeaser from '@/components/ui/text/lead-text-teaser'
import ContentTypeBadge from '@/components/ui/badge/content-type'

export default {
  components: {
    SubHeadline,
    SpunqImage,
    LeadTextTeaser,
    ArticleLink,
    ContentTypeBadge,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    screenType() {
      return this.$store.getters['screen/screenType']
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.floating-image-teaser {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &:hover {
    .floating-image-teaser__image {
      img {
        transform: scale(1.01);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @media all and (min-width: vars.$screen-width-767) {
      display: block;
    }
  }

  &__badges {
    width: auto;
    margin: 5px 0 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    order: 2;

    @media all and (min-width: vars.$screen-width-767) {
      margin: 0 0 5px 0;
    }
  }

  &__category {
    color: vars.$color-666;
    width: 100%;
    text-align: left;
    font-size: 13px;

    @media all and (min-width: vars.$screen-width-767) {
      margin-bottom: 0;
    }
  }

  &__headline {
    order: 3;
  }

  &__leadtext {
    order: 4;
  }

  & &__headline {
    font-size: 22px;
    line-height: 24px;
    margin: 0 0 10px 0;

    @media all and (min-width: vars.$screen-width-767) {
      font-size: 20px;
    }
  }

  &__link {
    color: vars.$color-text-dark-gray;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__image {
    max-width: 100%;
    margin-left: 0;
    order: 1;

    @media all and (min-width: vars.$screen-width-767) {
      float: right;
      max-width: 80px;
      margin-left: 10px;
      order: 3;
    }
  }

  &__footer {
    clear: right;
    font-size: 13px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media all and (min-width: vars.$screen-width-767) {
      padding-top: 20px;
      width: 100%;
      bottom: 0;
      font-size: 12px;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
      line-height: 1;
    }
  }

  &__author {
    color: vars.$color-text;
  }

  &__date {
    color: vars.$color-text;
  }
}
</style>

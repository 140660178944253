import CHANNEL from '@/gql/channels.gql'
import STARAUTHORS from '@/gql/starauthors.gql'

const asyncData = async function ({ app, route, redirect, error }) {
  const client = app.apolloProvider.defaultClient
  const values = {
    channels: [],
    starAuthors: [],
  }
  try {
    const slug = route.params.slug
    /**
     * NOTE: params.channelSlugs is a string when resolving, so we need to create the
     * array manually.
     */
    const channelSlugs =
      typeof route.params.channelSlugs === 'string'
        ? route.params.channelSlugs.split('/')
        : [].concat(route.params.channelSlugs)
    const queryParameter = [...channelSlugs, slug].filter((c) => !!c).join('/')
    const { data } = await client.query({
      query: CHANNEL,
      variables: {
        portalId: app.context.$config.portalId,
        filter_slug: queryParameter,
      },
    })
    if (data.getChannelsByPortalId === null) {
      return error({ statusCode: 404 })
    }
    values.channels = data.getChannelsByPortalId.data
  } catch (e) {
    return error({ statusCode: 500 })
  }
  try {
    if (values.channels.length > 0) {
      const boxes = values.channels[0].relationships.boxes
      if (
        boxes.filter((box) => box.attributes.templateAlias.replace(/cms\.furche\._boxes\.Box\./, '') === 'authors')
          .length > 0
      ) {
        const { data } = await client.query({
          query: STARAUTHORS,
          variables: {
            portalId: vars.$app.context.$config.portalId,
            filter_isStarAuthor: true,
            limit: 50,
            order: 'lastName',
          },
        })
        if (data.getAuthorsByPortalId !== null) {
          values.starAuthors = data.getAuthorsByPortalId.data
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return values
}
export default asyncData

<template>
  <div class="books-and-film-box">
    <div class="teaser film-teaser">
      <i class="white-circle">
        <IconFilmcamera fill="#109AAA" />
      </i>
      <div class="teaser-text">
        <h2>FURCHE Filmkritiken</h2>
        <p>{{ box.attributes.templateOptions.filmTeaser }}</p>
        <LinkButton :link="{ path: '/kritik/film' }"> Eintauchen </LinkButton>
      </div>
    </div>

    <div class="teaser book-teaser">
      <i class="white-circle">
        <IconBook fill="#93141A" />
      </i>
      <div class="teaser-text">
        <h2>FURCHE Literaturkritiken</h2>
        <p>{{ box.attributes.templateOptions.bookTeaser }}</p>
        <LinkButton :link="{ path: '/kritik/literatur' }"> Entdecken </LinkButton>
      </div>
    </div>
  </div>
</template>

<script>
import IconFilmcamera from '@/components/ui/icons/icon-filmcamera.svg'
import IconBook from '@/components/ui/icons/icon-book.svg'
import LinkButton from '@/components/ui/button/link-button'

export default {
  components: {
    IconFilmcamera,
    IconBook,
    LinkButton,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss">
@use '../../../../css/import/vars';

.books-and-film-box {
  color: white;
  overflow: hidden;
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: vars.$screen-width-767) {
    max-width: 1170px;
    width: 100%;
    flex-direction: row;
  }

  .white-circle {
    margin: 0 auto 10px auto;
    background-color: white;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    display: none;

    @media screen and (min-width: vars.$screen-width-767) {
      display: flex;
      margin: auto 40px auto 20px;
      justify-content: center;
      align-items: center;
    }
  }

  .teaser {
    padding: 37px 20px 37px 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media screen and (min-width: vars.$screen-width-767) {
      flex-direction: row;
      max-width: calc(50% - 15px);
      width: 100%;
      margin-bottom: 0;
    }
  }

  h2 {
    margin: 0;
  }

  .teaser-text p {
    font-family: vars.$georgia;
    font-size: 16px;
    letter-spacing: -0.23px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .film-teaser {
    background-color: #0c8696;
    @media screen and (min-width: vars.$screen-width-767) {
      margin-right: 30px;
    }
  }

  .book-teaser {
    background-color: #93141a;
    @media screen and (min-width: vars.$screen-width-767) {
      background-image: url('../../../../assets/svg/book-suggestion.svg');
      background-repeat: no-repeat;
      background-position: bottom right;
    }
  }

  .link-button {
    background-color: #ffffff;
    color: #0c8696;
    padding: 10px;
    @media screen and (min-width: vars.$screen-width-767) {
    }
  }

  .link-button:nth-child(2n) {
    color: #93141a;
  }
}
</style>

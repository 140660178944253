<template>
  <div v-if="adsEnabled && showAdPosition" class="med-rec">
    <div :id="desktopId"></div>
    <div v-if="mobileId" :id="mobileId"></div>
    <div class="med-rec__hint">Werbung</div>
  </div>
</template>

<script>
export default {
  props: {
    desktopId: {
      type: String,
      required: true,
    },
    mobileId: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    adsEnabled() {
      return this.$config.adsEnabled
    },
    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },
    showAdPosition() {
      return !(
        this.noAdPositions.map((element) => element.formatId).includes(this.desktopId.replace('sas_', '')) ||
        this.noAdPositions.map((element) => element.formatId).includes(this.mobileId.replace('sas_', ''))
      )
    },
  },
}
</script>

<style lang="scss">
@use '../../css/import/vars';
.med-rec {
  width: 300px;

  &__hint {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #999;
  }
}
</style>

<template>
  <div class="grid-row">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@use '../../../css/import/vars.scss';

.grid {
  &-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    margin: 0 20px 0 20px;

    @media all and (min-width: vars.$screen-width-767) {
      flex-direction: row;
      justify-content: center;
      width: 100%;
      max-width: vars.$channel-max-width;
      margin: 0 auto 20px auto;
      border-bottom: 1px solid vars.$color-light-grey;
    }

    &:last-child {
      border-bottom: none;
    }

    &--wrap {
      @media all and (min-width: vars.$screen-width-767) {
        flex-wrap: wrap;
      }
    }

    &.herogrid {
      max-width: vars.$page-max-width;
      border-bottom: none;
      margin: 0 auto 0 auto;
    }
  }
}
</style>

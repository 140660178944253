<template>
  <div class="author-list" :class="{ 'author-list--names-only': namesOnly }">
    <div
      v-for="(author, index) in authors"
      :key="index"
      class="author-list__item"
      :class="authors.length === 1 ? 'author-list__item--single' : null"
    >
      <Author
        :author="author"
        :teaser-image="articleType === 'Meinung'"
        :name-only="namesOnly"
        :position-only="positionOnly"
      />
      <div v-if="authors.length > 1 && index < authors.length - 1" class="author-list__and">
        <span v-if="index < authors.length - 2"> ,&nbsp; </span>
        <span v-else-if="index === authors.length - 2"> &nbsp;und&nbsp; </span>
      </div>
    </div>
  </div>
</template>

<script>
import Author from '@/components/ui/author/default'

export default {
  components: {
    Author,
  },
  props: {
    authors: {
      type: Array,
      required: true,
    },
    articleType: {
      type: String,
      required: true,
    },
    namesOnly: {
      type: Boolean,
      required: true,
    },
    positionOnly: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.author-list {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 7px 0;

  &--names-only {
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--single {
      flex-grow: 1;
    }
  }

  &__and {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    color: vars.$color-line-gray;
  }
}
</style>

<template>
  <div class="authors-redaction">
    <span class="authors-redaction__redaction">Redaktion: &nbsp;</span>
    <AuthorList :authors="authors" :names-only="textOnly" :article-type="articleType" :position-only="false" />
  </div>
</template>

<script>
import AuthorList from '@/components/ui/author/default-list'

export default {
  components: {
    AuthorList,
  },
  props: {
    authors: {
      type: Array,
      required: true,
    },
    articleType: {
      type: String,
      required: true,
    },
    textOnly: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars.scss';

.authors-redaction {
  padding-bottom: 40px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;

  .author__link {
    color: vars.$color-text-dark-gray;
  }

  &__redaction {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    color: vars.$color-line-gray;
  }
}

.darkmode {
  &.hero-teaser {
    .author__link {
      color: vars.$color-white;
    }

    .author-list__and,
    .authors-redaction__redaction {
      color: vars.$color-white;
      opacity: 0.6;
    }
  }
}
</style>

<template>
  <div class="eight-teaser-row-with-ads">
    <GridRow class="four-teaser-row-with-ads">
      <div
        v-for="(teaser, index) in firstRowTeasers"
        :key="teaser.id || index"
        class="one-fourth-teaser"
        :class="{ 'one-fourth-teaser--medium-r': index === 2 && adsEnabled && showAdPosition }"
      >
        <template v-if="index === 2 && adsEnabled && showAdPosition">
          <MediumRectangle desktop-id="sas_23175" mobile-id="sas_23366" />
        </template>
        <template v-else>
          <OpinionTeaser v-if="teaser.attributes.textType === 'Meinung'" :article="teaser" />
          <DefaultTeaser v-else :article="teaser" :lazy="lazy" />
        </template>
      </div>
    </GridRow>

    <GridRow class="four-teaser-row">
      <div v-for="(teaser, index) in secondRowTeasers" :key="teaser.id || index" class="one-fourth-teaser">
        <OpinionTeaser v-if="teaser.attributes.textType === 'Meinung'" :article="teaser" />
        <DefaultTeaser v-else :article="teaser" :lazy="lazy" />
      </div>
    </GridRow>
  </div>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import DefaultTeaser from '@/components/ui/teaser/default-teaser'
import OpinionTeaser from '@/components/ui/teaser/opinion-teaser'
import MediumRectangle from '@/components/ads/medium-rectangle'

export default {
  name: 'EightTeaserRowWithAds',
  components: {
    GridRow,
    DefaultTeaser,
    OpinionTeaser,
    MediumRectangle,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    showAds: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    allTeasers() {
      if (!this.adsEnabled) return [...this.box.relationships.articles]
      const articles = [...this.box.relationships.articles]
      if (articles.length > 7) {
        const lastArticle = articles.pop()
        articles.splice(2, 0, lastArticle)
      }
      return articles
    },

    firstRowTeasers() {
      return this.allTeasers.slice(0, 4)
    },

    secondRowTeasers() {
      return this.allTeasers.slice(4, 8)
    },

    adsEnabled() {
      return this.$config.adsEnabled && this.showAds
    },

    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },

    showAdPosition() {
      return !(
        this.noAdPositions.map((element) => element.formatId).includes('23175') ||
        this.noAdPositions.map((element) => element.formatId).includes('23366')
      )
    },
  },
}
</script>

<style lang="scss"></style>

<template>
  <GridRow class="two-teaser-row">
    <template v-for="article in box.relationships.articles">
      <HintTeaser :key="article.id" :article="article" class="two-teaser-row__teaser" :lazy="true" />
    </template>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import HintTeaser from '@/components/ui/teaser/hint'

export default {
  components: {
    GridRow,
    HintTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@use '../../../../css/import/vars';
.two-teaser-row {
  display: flex;
  flex-direction: column;

  @media all and (min-width: vars.$screen-width-767) {
    flex-direction: row;
  }

  &__teaser {
    &:first-child {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid vars.$color-light-grey;

      @media all and (min-width: vars.$screen-width-767) {
        border-right: 1px solid vars.$color-light-grey;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @media all and (min-width: 1171px) {
        padding-left: 0;
      }
    }

    &:last-child {
      @media all and (min-width: 1171px) {
        padding-right: 0;
      }
    }
  }
}
</style>

<template>
  <p :class="`leadtext vars.${modifier}`">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    modifier: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.leadtext {
  margin-bottom: 1rem;
  color: vars.$color-black;

  &--section {
    font-family: vars.$georgia;
  }

  &--teaser {
    font-family: vars.$georgia;
  }

  &--article-header {
    font-size: 18px;
  }
}
</style>

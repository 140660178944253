<template>
  <div class="timeline-visual">
    <div class="html-timeline">
      <div :style="pointerStyle" class="timeline__arrow" :data-date="`${dataDate}`" />
      <div class="timeline__lines" :style="linesStyle">
        <i v-for="tick in ticks" :key="tick">
          <span>{{ tick }}</span>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    dateObject() {
      return new Date(this.date)
    },
    year() {
      let year = this.dateObject.getFullYear()
      if (year < 1960) {
        year = year - 5
      }
      return year
    },
    dataDate() {
      return new Intl.DateTimeFormat(this.$config.locale, { dateStyle: 'long' }).format(this.dateObject)
    },
    ticks() {
      return [1945, 1960, 1980, 2000, 2020]
    },
    allPeriods() {
      return (new Date().getFullYear() - 1940) / 20
    },
    periods() {
      return (this.year - 1940) / 20
    },
    pointerStyle() {
      return { transform: `translateX(${Math.floor(this.periods * 80)}px)` }
    },
    linesStyle() {
      return { width: `${this.allPeriods * 80}px` }
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.timeline-visual {
  margin-top: 1rem;
  margin-bottom: 2rem;
  transform: scale(0.75);

  @media all and (min-width: vars.$screen-width-767) {
    margin-top: 1rem;
    margin-bottom: 4rem;
    margin-left: 0;
    transform: scale(1);
  }
}

.html-timeline {
  margin-top: 1rem;
  font-family: vars.$source-sans-pro-variable;
  font-weight: 700;
  font-variation-settings: 'wght' 700;
  font-size: 13px;

  .timeline__arrow {
    position: relative;
    animation-name: timeline;
    animation-timing-function: cubic-bezier(0.89, 0.03, 0.53, 0.545);
    animation-duration: 1200ms;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
    display: inline-flex;
    justify-content: center;
    width: auto;
    z-index: 2;

    @media print {
      animation-duration: 0;
      animation-name: none;
      animation-timing-function: none;
    }

    &:before {
      content: attr(data-date);
      position: absolute;
      bottom: 6px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: vars.$color-white;
      background: vars.$color-turquoise;
      font-weight: 600;
      font-variation-settings: 'wght' 600;
      border-radius: 2px;
      padding: 0 8px;
      text-align: center;
      line-height: 1;
      height: 26px;
      font-size: 13px;
      width: auto;
      white-space: nowrap;
      z-index: 2;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        line-height: 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 12px;
      height: 12px;
      background-color: vars.$color-turquoise;
      transform: rotate(45deg);
      z-index: 1;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: -6px;
      }
    }
  }

  .timeline__lines {
    display: flex;
    border-top: 1px solid vars.$color-white;
    position: relative;

    i {
      height: 9px;
      width: 1px;
      margin-right: 79px;
      font-style: normal;
      background: vars.$color-line-gray;
      font-family: vars.$source-sans-pro-variable;
      font-weight: 400;

      &:last-child {
        margin-right: 0;
      }

      @media all and (min-width: vars.$screen-width-767) {
        background: vars.$color-white;
      }
      span {
        position: absolute;
        transform: translate3d(-50%, 9px, 0);
        color: vars.$color-line-gray;

        @media all and (min-width: vars.$screen-width-767) {
          color: vars.$color-white;
        }
      }
    }
  }
}

// https://css-tricks.com/animate-to-an-inline-style/
@keyframes timeline {
  0% {
    transform: translateX(-1px);
  }
}
</style>
